// Vue
import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized } from "vue-router"
import { i18n, loadLanguageAsync } from "@/i18n"
import * as Sentry from "@sentry/vue"

// Stores
import { useAuthenticationStore } from "@/stores/authentication"

// Types
import { PageTitleParamsType, type IPageTitleParams } from "@/types/Router.types"

// Middlewares
import auth from "@/middlewares/auth"
import config from "@/middlewares/config"

// Routes
import configurationRoutes from "./routes/configurationRoutes"
import authenticationRoutes from "./routes/authenticationRoutes"
import errorRoutes from "./routes/errorRoutes"
import establishmentRoutes from "./routes/establishmentRoutes"
import publicationRoutes from "./routes/publicationRoutes"
import reviewRoutes from "./routes/reviewRoutes"
import solicitationRoutes from "./routes/solicitationRoutes"
import userRoutes from "./routes/userRoutes"
import onboardingRoutes from "./routes/onboardingRoutes"
import freemiumRoutes from "./routes/freemiumRoutes"
import googleRoutes from "./routes/googleRoutes"
import professionalSpaceRoutes from "@/router/routes/professionalSpaceRoutes"
import facebookRoutes from "@/router/routes/facebookRoutes"
import {useEstablishmentStore} from "@/stores/establishment";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:diffuserType?",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        requiresConfig: true,
        middleware: [auth, config],
      },
    },

    {
      path: "/subscriptions",
      name: "subscription-management",
      component: () => import("@/views/subscriptions/SubscriptionsManagement.vue"),
      meta: {
        middleware: [auth],
        title: "subscriptions.page_title",
      },
    },

    {
      path: "/contactus",
      name: "app-contact",
      component: () => import("@/views/configuration/Contact.vue"),
    },

    // users
    ...userRoutes,
    ...authenticationRoutes,

    // onboarding
    ...onboardingRoutes,
    ...configurationRoutes,

    // establishment features
    ...establishmentRoutes,
    ...publicationRoutes,
    ...reviewRoutes,
    ...googleRoutes,
    ...facebookRoutes,

    // freemium only
    ...solicitationRoutes,
    ...freemiumRoutes,

    // apalaches
    ...professionalSpaceRoutes,

    // errors
    ...errorRoutes,
  ],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  await loadLanguages()

  if (!["error-version", "auth"].includes(to.name)) {
    await loadCredentials(to)
  }

  let path = handleMiddlewares(to, next)

  if (to.name !== "error-version" && to.meta.middleware && !to.meta.middleware.includes(auth)) {
    if (to.path !== "/error") {
      path = "/error"
    }

    if (to.path === "/error") {
      path = "/"
    }
  }

  changePageTitle(to)

  return next(path)
})

async function loadLanguages() {
  let locale = i18n.global.locale.value
  if (locale === undefined) {
    locale = import.meta.env.VITE_APP_I18N_LOCALE
  }

  return loadLanguageAsync(locale as string)
}

function handleMiddlewares(to: RouteLocationNormalized, next: NavigationGuardNext) {
  const middlewares = to.meta.middleware as any

  let path = null

  let middlewareBlock = false
  let errorPath = ""

  if (middlewares) {
    middlewares.forEach((middleware: (arg0: any) => any) => {
      if (!middlewareBlock) {
        const currentMiddleware = middleware(next)
        if (currentMiddleware.success === false) {
          middlewareBlock = true
          errorPath = currentMiddleware.path
        }
      }
    })
  }

  if (middlewareBlock) {
    path = errorPath
  }

  return path
}

/**
 * Modifier le titre de l'onglet du navigateur de la page courante
 */
function changePageTitle(to: RouteLocationNormalized) {
  const DEFAULT_TITLE = "common.appname"
  if (to.meta.title) {
    const params = to.meta.hasTitleParams as IPageTitleParams
    if (params) {
      /**
       * Le paramètre de type param permet d'utiliser la valeur de la requête dans le titre
       */
      if (params.type === PageTitleParamsType.PARAM) {
        document.title = i18n.global.t(to.meta.title!.toString(), {
          [params.name]: convertSlugToString(to.params[params.name.toString()].toString()),
        })
      } else if (params.type === PageTitleParamsType.KEY) {
        /**
         * Le paramètre de type key permet d'utiliser la valeur de la requête dans le chemin de la traduction i18n
         */
        document.title = i18n.global.t(to.meta.title!.toString() + "." + to.params[params.name.toString()])
      }
    } else {
      document.title = i18n.global.t(to.meta.title!.toString())
    }
  } else {
    document.title = i18n.global.t(DEFAULT_TITLE)
  }
}

async function loadCredentials(to: RouteLocationNormalized) {
  const authStore = useAuthenticationStore()
  const establishmentStore =   useEstablishmentStore()

  if (to.query.token !== undefined) {
    localStorage.setItem("token", to.query.token!.toString())
    localStorage.setItem("tokenType", "Bearer")

    await loadUser()
    await establishmentStore.initEstablishment()
  } else if (
    to.meta !== undefined &&
    to.meta.middleware !== undefined &&
    to.meta.middleware!.includes(auth) &&
    authStore.user
  ) {
    await loadUser()
  }
}

async function loadUser() {
  const authStore = useAuthenticationStore()
  const env = import.meta.env.VITE_ENV

  const callback = () => {
    const userConf = {
      user_id: "uska-" + authStore.user!.id,
      name: authStore.user!.first_name + " " + authStore.user!.last_name,
      email: authStore.user!.email,
      role: authStore.user!.role.code,
      account_configured: authStore.user!.account.configured,
    }

    setTimeout(() => {
      if (window.intercomSettings) {
        Object.assign(window.intercomSettings, userConf)
      }
    }, 2000)

    if (env === "production") {
      Sentry.setContext("user", {
        id: authStore.user!.id,
        email: authStore.user!.email,
      })
    }
  }
  await authStore.me(callback)
}


function convertSlugToString(text: String): String {
  return text.replace(/-/, " ")
}

export default router
